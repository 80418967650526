import React, { useEffect, useState } from "react";
import { Button, Avatar, Card } from "antd";
import {
  DownOutlined,
  PlusOutlined,
  RightOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Typography } from "antd";

import {
  current_org,
  clearTabStates,
  initAnalyticsLabel,
  initCurrentOrg,
  showOrg,
  updateOrgNameId,
  changeLoaderStatus,
  updateCareerPageDetails,
  initSources,
  integratedSlackMember,
  add_current_plan,
  getActiveBoard,
  initJobPlatform,
  updateUser,
  addDomainOfOrg,
  updateEsignDetails,
} from "../../redux/actions/opening/action";
import {
  Domain,
  eSignDetails,
  IntegratedMember,
  loginUser,
  Orgs,
  Plan,
  Product,
} from "../../type/type.d";
import { ThirdPartyIntegrations } from "../../type/type.d";
import { Source } from "../../lib/apiRes";
import {
  getAllSources,
  getAnalyticsListLabel,
  getAvailableIntegrations,
  getBillingActivePlans,
  getDomain,
  getEsignIntegration,
  getIntegratedSlackMember,
  getInvitations,
  getOrgDetails,
  getSubscriptionDetails,
  updateInvitationStatusApi,
} from "../../lib/api";
import { getUser } from "../../lib/api";
import { useHistory } from "react-router-dom";
import { orgConfigurationMiddleware } from "../../service/orgConfigurationMiddleware";
import { PlansName } from "../../constant";
import { OWNER } from "../../constant";
import NoOrganizations from "./NoOrgAndInvite";
const { Text } = Typography;

type orgSwitcherProps = {
  loginUser: loginUser;
  applicationId: string;
  updateUser(user: any): void;
  updateIdName(orgData: any): void;
  add_current_plan: (product: Product[]) => void;
  addOrg(orgData: any): void;
  initOrg(): void;
  clearTabStates(): void;
  currentOrg(orgData: any): void;
  initAnalyticsLabel: (label: any) => void;
  changeInLoaderStatus(payload: {
    loader: boolean;
    message: string;
    skeleton: boolean;
  }): void;
  integratedSlackMember(IntegratedMember: IntegratedMember[]): void;
  updateCareerPageDetails(details: { [key: string]: string }): void;
  initSources(sources: Source[]): void;
  initJobPlatform(Integrations: ThirdPartyIntegrations[]): void;
  getActiveBoard(data: any): void;
  addDomainOfOrg(domainRes: Domain): void;
  updateEsignDetails: (data: eSignDetails) => void;
};

interface LoadingState {
  [key: string]: {
    join: boolean;
    decline: boolean;
  };
}
const WorkspaceSelector = (props: orgSwitcherProps) => {
  const [invitations, setInvitations] = useState([]);
  const [loadingState, setLoadingState] = useState<LoadingState>({});
  useEffect(() => {
    localStorage.removeItem("orgCreationStatus");
    getUser(props.loginUser.user.id).then((res) => {
      if (!res?.verified) {
        history.push("/onboarding");
      }
      props.updateUser(res);
    });
    props.initOrg();
    localStorage.removeItem("orgId");

    props.changeInLoaderStatus({ loader: false, message: "", skeleton: false });
    props.clearTabStates();
    getInvitations(props.loginUser.user.email).then((invitations: any) => {
      setInvitations(invitations);
    });
  }, []);
  const history = useHistory();
  const handleCreateOrg = (e: any) => {
    e.preventDefault();
    //   setClicked(true);
    history.push("/create-org");
  };

  const lunchOrg = (applicantion_id: string, role: string) => {
    sessionStorage.setItem("pageLoading", true.toString());
    props.changeInLoaderStatus({
      loader: true,
      message: "Stepping into your organization... Almost there!",
      skeleton: false,
    });

    getOrgDetails(applicantion_id)
      .then((appRes) => {
        localStorage.setItem("orgId", appRes.application.id);
        props.updateCareerPageDetails(appRes.application.data);
        getBillingActivePlans("tacitbase")
          .then((res) => {
            const unique: Product[] = [];
            if (res.plans && Array.isArray(res.plans)) {
              res.plans.forEach((plan: Plan) => {
                if (!unique.some((item) => item.id === plan.product.id)) {
                  unique.push(plan.product);
                }
              });
              props.add_current_plan(unique);
            }
          })
          .catch((err) => {
            console.log("Error fetching billing plans:", err);
          });
        return orgConfigurationMiddleware(appRes, props.loginUser).then(
          (flag) => {
            localStorage.setItem("orgId", appRes.application.id);
            if (flag) {
              getSubscriptionDetails()
                .then((res) => {
                  props.getActiveBoard(res?.subscriptions?.job_board);
                })
                .catch((err) => {
                  console.log("Error getting subscription details", err);
                });
              getEsignIntegration()
                .then((res: any) => {
                  if (res?.id) {
                    props.updateEsignDetails({
                      connected: true,
                      tools: [res?.client],
                    });
                  }
                })
                .catch((err) => {
                  console.log("Error getting esign details", err);
                });
              sessionStorage.removeItem("slackCode");
              let updatedOrg = {
                applicationId: applicantion_id,
                id: appRes.application.id,
                applicationName: appRes.application.name,
                verified: true,
                data: {
                  logo: "",
                  description: "",
                },
                roles: role,
              };
              props.updateIdName(updatedOrg);
              props.currentOrg(appRes);

              if (
                appRes.setup_stages.every(
                  (currentStage) => currentStage.stage === "done"
                )
              ) {
                if (
                  appRes.customer?.subscriptions?.data[0]?.status ===
                    "active" ||
                  appRes.customer?.subscriptions?.data[0]?.status ===
                    "trialing" ||
                  appRes.customer.metadata.plan.toLowerCase() ===
                    PlansName.free.toLowerCase() ||
                  appRes.customer.metadata.plan.toLowerCase() ===
                    PlansName.free_trail
                ) {
                  getDomain().then((res) => {
                    props.addDomainOfOrg(res);
                  });
                  history.push(`/o/${applicantion_id}/ats`);
                  return getAnalyticsListLabel()
                    .then((analyticsLabels) => {
                      props.initAnalyticsLabel(analyticsLabels);
                    })
                    .then(() => {
                      return getIntegratedSlackMember().then((Slackmembers) => {
                        integratedSlackMember(Slackmembers);
                        return getAllSources().then((source) => {
                          props.initSources(source);
                          return getAvailableIntegrations().then((integ) => {
                            props.initJobPlatform(integ);
                          });
                        });
                      });
                    });
                } else if (role.toUpperCase() === OWNER.toUpperCase()) {
                  window.location.href = appRes.dashboard.url;
                } else {
                  history.push("/contact-admin");
                }
              } else {
                sessionStorage.setItem("pageLoading", true.toString());
                history.push(`/o/start-setup`);
              }
            } else {
              // Flag is false, handle accordingly
              console.log("Token exchange failed or flag is false");
            }
          }
        );
      })
      .finally(() => {
        sessionStorage.setItem("pageLoading", false.toString());
        props.changeInLoaderStatus({
          loader: false,
          message: "",
          skeleton: false,
        });
      })
      .catch((error) => {
        // Handle errors from orgConfigurationMiddleware or getOrgDetails
        console.error(
          "Error in token exchange middleware or getOrgDetails:",
          error
        );
        sessionStorage.setItem("pageLoading", false.toString());
        props.changeInLoaderStatus({
          loader: false,
          message: "",
          skeleton: false,
        });
      });
  };

  const lunchInvitedOrganisation = (
    applicantion_id: any,
    e: any,
    id: string,
    orgRole: string
  ) => {
    e.stopPropagation();

    setLoadingState((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        join: true,
      },
    }));

    getOrgDetails(applicantion_id).then((appRes) => {
      const statusUpdate = {
        id: id,
        status: "ACCEPTED",
      };
      updateInvitationStatusApi(statusUpdate).then((res) => {
        if (res) {
          if (res.status == "ACCEPTED") {
            setLoadingState((prevState) => ({
              ...prevState,
              [id]: {
                join: false,
                decline: false,
              },
            }));
          }

          orgConfigurationMiddleware(appRes, props.loginUser).then((flag) => {
            if (flag) {
              getSubscriptionDetails()
                .then((res) => {
                  props.getActiveBoard(res?.subscriptions?.job_board);
                })
                .catch((err) => {
                  console.log("Error getting subscription details", err);
                });
              // updateInvitationStatus(statusUpdate, appRes.application.id);
              sessionStorage.removeItem("slackCode");

              let updatedOrg = {
                applicationId: applicantion_id,
                id: appRes.application.id,
                applicationName: appRes.application.name,
                verified: true,
                data: {
                  logo: "",
                  description: "",
                },
                roles: orgRole,
              };
              props.updateIdName(updatedOrg);
              props.currentOrg(appRes);
              history.push({
                pathname: `/o/${applicantion_id}/ats`,
              });
              getAnalyticsListLabel()
                .then((analyticsLabels) => {
                  props.initAnalyticsLabel(analyticsLabels);
                })
                .catch((error) => {
                  console.error("Error fetching analytics labels:", error);
                });
            }
          });
        }
      });
    });
  };

  const declineOrg = (orgid: string) => {
    setLoadingState((prevState) => ({
      ...prevState,
      [orgid]: {
        join: false,
        decline: false,
      },
    }));

    let newInvitations = invitations.filter((org: any) => org.id != orgid);
    setInvitations(newInvitations);
  };
  const updateInvitationStatus = async (
    req: { id: string; status: any },
    applicantion_id: string
  ) => {
    updateInvitationStatusApi(req).then((res: any) => {
      if (res) {
        if (req.status == "ACCEPTED") {
          setLoadingState((prevState) => ({
            ...prevState,
            [req?.id]: {
              join: false,
              decline: false,
            },
          }));

          props.changeInLoaderStatus({
            loader: false,
            message: "",
            skeleton: true,
          });
          history.push({
            pathname: `/o/${applicantion_id}/ats`,
          });
          getAnalyticsListLabel()
            .then((analyticsLabels) => {
              initAnalyticsLabel(analyticsLabels);
            })
            .catch((error) => {
              console.error("Error fetching analytics labels:", error);
            });
        } else {
          declineOrg(req.id);
        }
      }
    });
  };

  const [showAll, setShowAll] = useState({ org: false, invitation: false });

  const visibleOrgs = showAll.org
    ? props?.loginUser?.user?.registrations?.sort((a: Orgs, b: Orgs) =>
        a?.username?.localeCompare?.(b?.username)
      )
    : props?.loginUser?.user?.registrations
        ?.sort((a: Orgs, b: Orgs) => a?.username?.localeCompare?.(b?.username))
        ?.slice(0, 2);

  const visibleInvitations = showAll.invitation
    ? invitations
    : invitations.slice(0, 2);

  // const hasLimitedOrgs = props?.loginUser?.user?.registrations?.length <= 2;

  return (
    <div className="w-full h-full  overflow-y-hidden rounded-md bg-white">
      {invitations.length == 0 &&
      props?.loginUser?.user?.registrations.length == 0 ? (
        <NoOrganizations />
      ) : (
        <div className="w-full h-full flex justify-start items-start">
          <Card
            bordered={false}
            className=" w-full p-6 h-full create-org-card"
            style={{ boxShadow: "none" }}
          >
            <div className="flex flex-col h-full">
            <div className="flex items-center justify-between pb-4">

              <Text className="text-lg font-semibold">
                Available Organizations
              </Text>{" "}
              <Button className="gap-2" onClick={handleCreateOrg}>
                <PlusOutlined size={16} />
                Create New Organization
              </Button>
              </div>
              <div className="flex flex-col flex-grow h-full  overflow-hidden">
              {/* Invitations Section */}
              {invitations?.length > 0 && (
                <div className="space-y-6 border rounded-lg px-6 py-4 mb-4" style={{ maxHeight: "24vh",minHeight:"180px", overflowY: "auto" }}>
                  <h3 className="font-medium text-lg">
                    Accept an invitation
                  </h3>

                  <div className="space-y-3">
                    {visibleInvitations.map((invitation: any, key: number) => (
                      <div
                        className="flex flex-col lg:flex-row lg:items-center gap-4 p-4 rounded-lg hover:bg-gray-50 cursor-pointer transition-colors duration-200 border border-gray-100"
                        key={key}
                      >
                        <div className="flex items-center justify-between lg:justify-start flex-1">
                          <div className="flex items-center space-x-4">
                            <Avatar
                              shape="square"
                              size="large"
                              className="bg-tacitbase-primary1 uppercase text-lg flex-shrink-0"
                            >
                              {invitation.application_name[0]}
                            </Avatar>

                            <Text
                              ellipsis={{
                                tooltip: invitation.application_name,
                              }}
                              className="max-w-[150px]"
                            >
                              {invitation.application_name}
                            </Text>
                          </div>
                        </div>

                        <div className="flex justify-end space-x-2 w-full lg:w-auto">
                          <Button
                            htmlType="submit"
                            danger
                            size="small"
                            loading={loadingState[invitation.id]?.decline}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              updateInvitationStatus(
                                {
                                  id: invitation.id,
                                  status: "REJECTED",
                                },
                                invitation.application_id
                              );
                            }}
                            className="flex-1 lg:flex-none"
                          >
                            Decline
                          </Button>
                          <Button
                            size="small"
                            onClick={(e) => {
                              lunchInvitedOrganisation(
                                invitation.application_id,
                                e,
                                invitation.id,
                                invitation.role
                              );
                            }}
                            loading={loadingState[invitation.id]?.join}
                            className="min-w-[80px] flex-1 lg:flex-none"
                          >
                            Join
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>

                  {invitations?.length > 2 && (
                    <Button
                      type="text"
                      onClick={() =>
                        setShowAll((prev) => ({
                          ...prev,
                          invitation: !prev.invitation,
                        }))
                      }
                      className="text-gray-600 hover:text-gray-800 mt-2"
                    >
                      <span className="flex items-center gap-2">
                        {showAll.invitation ? (
                          <>
                            Show Less <UpOutlined className="text-xs" />
                          </>
                        ) : (
                          <>
                            Show More ({invitations?.length - 2}){" "}
                            <DownOutlined className="text-xs" />
                          </>
                        )}
                      </span>
                    </Button>
                  )}
                </div>
              )}

              {/* Organizations Section */}
              {visibleOrgs?.length > 0 &&(<div className="border rounded-lg p-4 space-y-4 overflow-y-auto">
                <h3 className="font-medium text-lg">Your Organizations</h3>
                <div className="space-y-3">
                  {visibleOrgs?.map((org: any) => (
                    <div
                      key={org.id}
                      role="button"
                      tabIndex={0}
                      onClick={() => lunchOrg(org.applicationId, org.roles[0])}
                      onKeyDown={() => {}}
                      className="flex items-center justify-between p-4 rounded-lg border bg-white hover:bg-gray-50 cursor-pointer transition-colors"
                    >
                      <div className="flex items-center gap-3">
                        <div className="w-10 h-10 rounded bg-blue-100 flex items-center justify-center">
                          {org["data"] && org.data.logo ? (
                            <Avatar
                              shape="square"
                              size="large"
                              src={org["data"].logo}
                              className="text-lg"
                            />
                          ) : (
                            <Avatar
                              shape="square"
                              size="large"
                              className="bg-tacitbase-primary1 uppercase text-lg"
                            >
                              {org.username?.[0]}
                            </Avatar>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <Text
                            ellipsis={{ tooltip: org.username }}
                            style={{ maxWidth: 150 }}
                          >
                            {org.username}
                          </Text>
                          <div
                              className="text-xs px-0.5 py-0.5  w-max rounded border"
                              style={{
                                borderColor: "#3B3898",
                                color: "#3B3898",
                              }}
                            >
                            {org.roles?.[0]}
                          </div>
                        </div>
                      </div>
                      <RightOutlined className="text-gray-400" />
                    </div>
                  ))}
                </div>
                {props?.loginUser?.user?.registrations?.length > 2 && (
                  <Button
                    type="text"
                    onClick={() =>
                      setShowAll((prev) => ({ ...prev, org: !prev.org }))
                    }
                    className="text-gray-600 hover:text-gray-800 mt-2"
                  >
                    <span className="flex items-center gap-2">
                      {showAll.org ? (
                        <>
                          Show Less <UpOutlined className="text-xs" />
                        </>
                      ) : (
                        <>
                          Show More (
                          {props?.loginUser?.user?.registrations?.length - 2}
                          ) <DownOutlined className="text-xs" />
                        </>
                      )}
                    </span>
                  </Button>
                )}
              </div>)}
            </div>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

const mapPropsToState = (dispatch: Dispatch) => ({
  addOrg: (orgData: any) => dispatch(showOrg(orgData)),
  initOrg: () => dispatch(initCurrentOrg()),
  clearTabStates: () => dispatch(clearTabStates()),
  initAnalyticsLabel: (label: any) => dispatch(initAnalyticsLabel(label)),
  updateIdName: (orgData: any) => dispatch(updateOrgNameId(orgData)),

  currentOrg: (orgData: any) => dispatch(current_org(orgData)),
  changeInLoaderStatus: (payload: {
    loader: boolean;
    message: string;
    skeleton: boolean;
  }) => dispatch(changeLoaderStatus(payload)),
  updateCareerPageDetails: (details: { [key: string]: string }) =>
    dispatch(updateCareerPageDetails(details)),
  integratedSlackMember: (IntegratedMember: IntegratedMember[]) =>
    dispatch(integratedSlackMember(IntegratedMember)),
  initSources: (sources: Source[]) => dispatch(initSources(sources)),
  initJobPlatform: (Integrations: ThirdPartyIntegrations[]) =>
    dispatch(initJobPlatform(Integrations)),
  add_current_plan: (data: Product[]) => dispatch(add_current_plan(data)),
  getActiveBoard: (data: any) => dispatch(getActiveBoard(data)),
  addDomainOfOrg: (domainRes: Domain) => dispatch(addDomainOfOrg(domainRes)),
  updateUser: (user: any) => dispatch(updateUser(user)),
  updateEsignDetails: (data: eSignDetails) =>
    dispatch(updateEsignDetails(data)),
});
const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  applicationName: state.opening.applicationName,
});

export default connect(mapStateToProps, mapPropsToState)(WorkspaceSelector);